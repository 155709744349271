.numpad-container {
  height: 100%;
  padding-bottom: 0;
  input {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
    font-size: 100px;
    color: red;
    font-weight: bold;
    text-align: center;
    background: black;
    border: 0;
  }
}

.numPad {
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 322px);
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 0;
  .numRow {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    &:nth-child(2) {
      border-top: none;
    }
    &:nth-child(3) {
      border-top: none;
      border-bottom: none;
    }
    .numKey {
      width: 100%;
      position: relative;
      cursor: pointer;
      span {
        font-size: 72px;
        font-weight: lighter;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
      }
      &:first-of-type {
        border-right: 1px solid black;
      }
      &:last-of-type {
        border-left: 1px solid black;
      }
    }
  }
  .numKey-C {
    color: orange;
  }

  .numKey-OK {
    color: green;
  }
}
