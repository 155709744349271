.smallImage {
  width: 150px;
}

.player-wrapper {
  position: relative;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.linkVideo {
  text-decoration: none !important;
}

.linkVideoText {
  color: black;
  font-size: 24px;
  width: 100%;
}

.backButton {
  margin-bottom: 40px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #006DB7;
  border-radius: 0 !important;
}

.backToTopButton {
  color: white;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #006DB7;
  border-radius: 0 !important;
}
.backToTopButton:visited {
  color: white;
  text-decoration: none;
}

#player iframe {
  background: black !important;
  width: 100%;
  height: 10px;
}

.player-wrapper {
  background: black;
}

.smallBackButton {
  text-transform: uppercase;
  font-weight: bold;
}

.bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}

.fullscreenButton {
  float: right;
  margin-top: -38px;
}
