header {
  background-color: #006DB7;
  padding-top: 25px;
  padding-bottom: 100px;
  width: 100%;
  position: relative;
  img {
    margin: 0 auto;
    display: block;
    height: 100%;
    width: auto;
  }
}