@import 'variables';
@import 'header';
@import 'numPad';
@import 'responsive';

html {
  body {
    background: white;
  }
}

html, body, .App, #root {
  height: 100%;
}

.App {
  max-width: 100%;
  overflow-x: hidden;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

* {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.container {
  margin: 0 auto;
  padding: 40px;
}

.Choice {
  margin: 0 auto;
  padding: 40px;

  .smallImage {
    max-height: 100%;
    display: block;
    float: left;
  }
  .smallText {
    color: black;
    font-size: 24px;
    float: left;
  }
  .row {
    height: 150px;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &:hover {
      a, h2 {
        color: #006db7;
      }
      .picBox {
        img {
          &:last-of-type {
            opacity: 0.5;
          }
        }
      }
    }
    a {
      height: 100%;
      width: 100%;
      display: block;
      text-decoration: none;
      div {
        height: 100%;
        width: 100%;
        display: block;
      }
    }
    img {
      height: 100%;
      width: auto;
    }

    .picBox {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      img {
        &:first-of-type {
          margin-right: 20px;
        }
        &:last-of-type {
          height: 40px;
          opacity: 0.2;
        }
      }

      .text-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        h2 {
          margin-bottom: 0;
        }
        p {
          color: rgba(0, 0, 0, 0.6);
          font-size: 20px;
        }
      }
    }
  }
}

.title-header {
  display: block;
  position: relative;
  h1 {
    width: calc(100% - 200px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    font-size: 28px;
    transform: translate(-50%, -50%);
  }
  .backButton {
    width: 100px;
    background: transparent;
    height: 40px;
    display: flex;
    align-items: center;
    align-content: center;
    text-decoration: none;
    span {
      color: $light-blue;
      text-transform: initial;
      font-size: 20px;
      margin-top: 2.5px;
    }
    img {
      height: 100%;
      width: auto;
      transform: rotateY(180deg);
      margin-left: -12.5px;
      margin-right: -3px;
    }
  }
}

.tour-overview {
  .row {
    height: 150px;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    img {
      height: 100%;
      width: auto;
    }
    a {
      overflow: hidden;
      height: 100%;
      width: 100%;
      display: block;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-container {
      height: 100%;
      width: 100%;
      position: relative;
      h2 {
        margin: 0;
      }
      span {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.3);
        margin: 0;
        position: absolute;
        bottom: 0;
      }
    }
    .smallImage {
      height: 139px;
      width: 139px;
      min-width: 139px;
      min-height: 139px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      margin-right: 20px;
      margin-left: 15px;
      &:before {
        content: '';
        position: absolute;
        left: -15px;
        width: 10px;
        height: 100%;
        top: 0;
        bottom: 0;
        background-color: #19b5fe;
      }
    }
    img {
      &:last-of-type {
        height: 40px;
        opacity: 0.2;
      }
    }
    &:hover {
      a, h2 {
        color: #006db7;
      }
      img {
        &:last-of-type {
          opacity: 0.5;
        }
      }
    }
  }
}

.search-container {
  background-color: lightgrey;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  &:focus, &:active, &:focus-within {
    .placeholder {
      display: none;
    }
  }
  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-content: center;
    align-items: center;
    opacity: 0.5;
    pointer-events: none;
    img {
      width: 15px;
      height: 15px;
      margin-top: -1px;
    }
    span {
      color: black;
      margin-top: 2px;
      margin-left: 7px;
    }

  }
  input {
    outline: none;
    border: none;
    background-color: white;
    padding: 5px 10px;
    width: 100%;
    border-radius: 5px;
  }
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid $light-blue; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
  animation: spin 2s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  .footer-inner {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    button {
      &.active, &:hover {
        opacity: 1;
        img {
          display: none;
          &.active {
            display: block;
          }
        }
        span {
          color: $light-blue;
        }
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border: none;
      background-color: transparent;
      outline: none;
      margin-bottom: -10px;
      opacity: 0.4;
      span {
        color: black;
        margin-top: 5px;
      }
      img {
        &.active {
          display: none;
        }
        width: 40px;
        height: 40px;
        display: block;
      }
    }
  }
}

.numpad-container {
  margin: 0 auto;
}


.video-overview-container {
  .video-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 120px;
    background-color: #000000;
    width: 100%;
    height: calc(100% - 120px);
    iframe {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
    }
  }
}
