@media only screen and (max-width: 1024px) {
	.container {
		max-width: 100% !important;
		width: 100% !important;
		&.numPad {
			max-width: 680px !important;
			padding: 0 40px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.container {
		&.numPad {
			padding: 0 !important;
			max-height: calc(100% - 311px) !important;
			width: calc(100% + 40px) !important;
    		margin-left: -20px;
		}
		max-width: 600px !important;
		padding: 0 40px !important;
	}
	.numpad-container {
		input {
			max-width: initial !important;
			margin-top: 20px !important;
			width: calc(100% + 40px) !important;
    		margin-left: -20px !important;
		}
	}
	.Choice .row .picBox .text-container p {
		font-size: 16px !important;
	}
	.Choice {
		.row {
			height: 100px !important;
			img {
				&:last-of-type {
					height: 30px !important;
				}
			}
		}
	}
	.tour-overview {
		.row {
			width: calc(100% + 35px) !important;
    		margin-left: -15px !important;
			img {
				&:last-of-type {
					height: 30px !important;
					margin-right: 15px !important;
				}
			}
			height: 100px !important;
			.smallImage {
				height: 95px !important;
    			width: 95px !important;
   				min-width: 95px !important;
    			min-height: 95px !important;
			}
		}
	}
	.text-container {
		span {
			font-size: 20px !important;
		}
	}
	h2 {
		font-size: 20px !important;
	}
	footer {
		.footer-inner {
			button {
				width: 75px !important;
    			height: 75px !important;
				img {
					width: 30px !important;
    				height: 30px !important;
				}
				span {
					margin-top: 5px;
					font-size: 12px !important;
				}
			}
			padding: 0 !important;
		}
	}
	.search-container {
		width: calc(100% + 40px) !important;
    	margin-left: -20px !important;
		margin-top: 20px !important;
	}
	.video-overview-container .video-container {
		height: calc(100% - 65px) !important;
		top: 65px !important;
	}
	.container {
		padding: 20px !important;
	}
	.title-header {
		h1 {
			font-size: 24px !important;
		}
		a {
			&.backButton {
				margin-bottom: 0 !important;
				height: 25px;
				img {
					margin-left: -7px !important;
				}
				span {
					margin-top: 0;
					font-size: 18px;
				}
			}
		}
	}
	header {
		a {
			img {
				height: 30px;
			}
		}
	}
	.loader {
		width: 40px !important;
		height: 40px !important;
	}
}


@media only screen and (max-width: 600px) {
	.numpad-container {
		input {
			font-size: 62px !important;
		}
	}
	.container.numPad {
		max-width: 600px !important;
		padding: 0 !important;
		max-height: calc(100% - 254px) !important;
		.numRow {
			.numKey {
				span {
					font-size: 32px !important;
				}
			}
		}
	}
	h1 {
		font-size: 20px !important;
	}
	h2 {
		font-size: 18px !important;
	}
	.title-header {
		h1 {
			width: calc(100% - 100px) !important;
			text-align: right !important;
			right: 0 !important;
			font-size: 20px !important;
			left: initial !important;
			transform: translate(0, -50%) !important;
		}
	}
}

@media only screen and (max-width: 400px) {
	.container.numPad {
		max-width: 600px !important;
		padding: 0 !important;
	}
	.numpad-container {
		input {
			max-width: initial !important;
			margin-top: 10px !important;
			width: calc(100% + 40px) !important;
    		margin-left: -20px !important;
		}
	}
	.tour-overview .row {
		width: calc(100% + 25px) !important;
    	margin-left: -5px !important;
    	height: 100px !important;
	}
	.video-overview-container .video-container {
		height: calc(100% - 45px) !important;
		top: 45px !important;
	}
	h1 {
		font-size: 18px !important;
	}
	h2 {
		font-size: 16px !important;
	}
	.title-header {
		h1 {
			font-size: 18px !important;
		}
	}
	.search-container {
		width: calc(100% + 20px) !important;
    	margin-left: -10px !important;
		margin-top: 10px !important;
	}
	.container {
		padding: 10px !important;
	}
	header {
		padding-bottom: 75px;
		a {
			img {
				height: 25px;
			}
		}
	}
}
